<template>
  <div class="cart-page">

    <div class="session session-content" style="padding-top: 25px;">
      <div class="content-inner">

        <div class="content-body">
          <div class="filter-warp">
            <div class="filter-city">
              <div class="filter-city-inner">
                <div class="city-active"><span>{{filter.paramsPdCityName}}</span></div>
                <div class="city-warp">
                  <div v-for="(pCities, province) in cities" :key="province" class="province-list">
                    <div class="province">{{province}}</div>
                    <div class="city-list">
                      <span v-for="(pCity, cityIndex) in pCities" :key="cityIndex" @click="onClickCity(pCity)">{{pCity}}</span>
                    </div>
                    <div class="clear"></div>
                  </div>
                </div>
              </div>
              <div class="clear"></div>
            </div>
            <div class="filter-items">
              <div class="filter-item filter-item-span">
                <div class="filter-time-title">品牌</div>
                <span @click="onClickBrand(null)" :class="!filter.paramsBrandReal?'active arg-item':'arg-item'">不限</span>
                <span v-for="(hot, index) in filterData.hotBrands" :key="index" @click="onClickBrand(hot)" :class="filter.paramsBrandReal==hot?'active arg-item':'arg-item'">{{ hot }}</span>
                <span class="arg-item arg-item-more" @click="isShowMoreBrand=!isShowMoreBrand">更多</span>

                <div class="brands-full" v-if="isShowMoreBrand">

                  <div class="brand-group">
                    <div  class="brand-item-warp" v-for="(brand, brandIndex) in brands.slice(0,7)" :key="'g1-'+brandIndex">
                      <div class="brand-letter"  >{{ brand.key }}</div>
                      <div class="brand-items">
                        <span :class="filter.paramsBrandReal==item.brandName?'active':''" @click="onClickBrand(item.brandName)" v-for="item in brand.list" :key="item.brandName">{{item.brandName}}</span>
                      </div>
                    </div>
                  </div>

                  <div class="brand-group brand-group-m">
                    <div  class="brand-item-warp" v-for="(brand, brandIndex) in brands.slice(7,15)" :key="'g2-'+brandIndex">
                      <div class="brand-letter"  >{{ brand.key }}</div>
                      <div class="brand-items">
                        <span :class="filter.paramsBrandReal==item.brandName?'active':''" @click="onClickBrand(item.brandName)" v-for="item in brand.list" :key="item.brandName">{{item.brandName}}</span>
                      </div>
                    </div>
                  </div>

                  <div class="brand-group">
                    <div  class="brand-item-warp" v-for="(brand, brandIndex) in brands.slice(15,26)" :key="'g3-'+brandIndex">
                      <div class="brand-letter"  >{{ brand.key }}</div>
                      <div class="brand-items">
                        <span :class="filter.paramsBrandReal==item.brandName?'active':''" @click="onClickBrand(item.brandName)" v-for="item in brand.list" :key="item.brandName">{{item.brandName}}</span>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <div class="filter-item filter-item-span">
                <div class="filter-time-title">车系</div>
                <span class="arg-item" :class="!filter.paramsPopSerial?'active arg-item':'arg-item'" @click="onClickSerial(null)">不限</span>
                <span v-if="!filter.paramsBrandReal && options">
                   <span v-for="(hot, index) in options.listpopcarbrand.slice(0,10)" :key="index" @click="onClickSerial(hot)" :class="filter.paramsPopSerial==hot?'active arg-item':'arg-item'">{{ hot }}</span>
                </span>

                <span :class="filter.paramsPopSerial==serial?'active arg-item':'arg-item'" v-for="(serial, serialIndex) in serials.slice(0,8)" :key="serialIndex" @click="onClickSerial(serial)">{{ serial }}</span>
                <span v-if="serials.length>8" class="arg-item arg-item-more" @click="isShowMoreSerial=!isShowMoreSerial">更多</span>
                <div v-if="isShowMoreSerial" class="serials-full">
                  <span v-for="(serial, serialIndex) in serials" :key="serialIndex" @click="onClickSerial(serial)"  :class="filter.paramsPopSerial==serial?'active':''">{{ serial }}</span>
                </div>
              </div>
              <div class="filter-item filter-item-price filter-item-span">
                <div class="filter-time-title">车价</div>
                <span v-for="(price, index) in filterData.prices" :key="index" :class="(filter.paramsLowPrice==price.low && filter.paramsHighPrice==price.height)?'active arg-item':'arg-item'" @click="onClickPrice(price.low,price.height)">{{ price.name }}</span>
                <el-input v-model="filter.paramsLowPrice" class="paramsLowPrice">
                  <template slot="append">万</template>
                </el-input>
                <el-input v-model="filter.paramsHighPrice" class="paramsHighPrice">
                  <template slot="append">万</template>
                </el-input>
                <el-button @click="onClickPrice(filter.paramsLowPrice,filter.paramsHighPrice)">确定</el-button>
                <!--<el-input
                    placeholder="请选择日期"
                    v-model="filter.prices">
                  <i slot="suffix" class="el-input__icon">万</i>
                </el-input>-->
              </div>
              <div class="filter-item" v-if="options">
                <div class="filter-time-title" style="margin-right: 25px;">更多</div>
                <el-select v-model="filter.paramsLowModelYear" placeholder="年款" @change="onChangeYear()">
                  <el-option
                      v-for="(item, index) in options.listmodelyear"
                      :key="index"
                      :label="item"
                      :value="item"
                  >
                  </el-option>
                </el-select>
                <span style="display: inline-block; margin-right: 15px;">至</span>
                <el-select v-model="filter.paramsHighModelYear" placeholder="年款" @change="onChangeYear()">
                  <el-option
                      v-for="(item, index) in options.listmodelyear"
                      :key="index"
                      :label="item"
                      :value="item"
                  >
                  </el-option>
                </el-select>

                <el-select style="width: 100px;" v-model="filter.paramsOutputStandard" placeholder="排放标准" @change="onChangeOutputStandard()">
                  <el-option
                      v-for="(item, index) in options.listoutputstandard"
                      :key="index"
                      :label="item"
                      :value="item">
                  </el-option>
                </el-select>

                <el-select style="width: 100px;" v-model="filter.paramsDriveMethod" placeholder="驱动类型" @change="onChangeDriveMethod()">
                  <el-option
                      v-for="(item, index) in options.listDrivemethod"
                      :key="index"
                      :label="item"
                      :value="item">
                  </el-option>
                </el-select>

                <el-select v-model="mileage" placeholder="里程" @change="onChangeMileage()">
                  <el-option
                      v-for="(item, index) in filterData.mileages"
                      :key="index"
                      :label="item.name"
                      :value="index">
                  </el-option>
                </el-select>

                <el-select v-model="filter.paramsOutputVolume" placeholder="排量" @change="onChangeOutputVolume()">
                  <el-option
                      v-for="(item, index) in options.listliter"
                      :key="index"
                      :label="item"
                      :value="item">
                  </el-option>
                </el-select>

                <el-select style="width: 100px;" v-model="filter.paramsOilType" placeholder="燃料类型" @change="onChangeOilType()">
                  <el-option
                      v-for="(item, index) in options.listoiltype"
                      :key="index"
                      :label="item"
                      :value="item">
                  </el-option>
                </el-select>
              </div>
              <div class="clear"></div>
            </div>
          </div>

          <div class="choose-tags">
            <div class="choose-active" v-if="tags.length>0">
              已选:
              <span v-for="(tag, index) in tags" :key="index" data-v-400840da="" class="el-tag el-tag--light">
              {{tag.name}}
              <i class="el-tag__close el-icon-close" @click="onClickDelTagName(tag.filter)"></i>
            </span>
            </div>
          </div>


          <div class="cart-list" v-loading="loading">
            <el-empty v-if="cars.length==0" description="暂无内容"></el-empty>
            <ul>
              <li v-for="(car, cartIndex) in cars" :key="cartIndex" @click="onClickShow(car.auCode)" >
                <img :src="fileUrl+'/'+car.acpLeftHead">
                <h3>{{ car.aciCarModel }}</h3>
                <div class="box-1">{{ car.aciYearStyle }} | 10.5万公里 | {{ car.pdCityName }}</div>
                <div class="box-2">
                  <strong>3.8</strong>万 预估价
                  <span>询价中</span>
                </div>
              </li>


              <li class="clear"></li>
            </ul>
          </div>
        </div>





      </div>
    </div>









  </div>
</template>

<script>

import {getOptions, getBrandOption, getCityOption, getSerialOption, getCars, getIp} from "@/api/car";
//import env from "@/env"

//const fileUrl = env.fileUrl

export default {

  data() {
    return {
      loading: true,
      activeName:'',
      title:'',
      options:null,
      brands:[],
      serials:[],
      cars:[],
      mileage:null,
     // fileUrl:fileUrl,
      fileUrl:'http://ucarunion.com:9801',
      filter:{
        paramsPdCityName:null,       //城市
        paramsBrandReal:null,        //品牌
        paramsPopSerial:null,        //车系
        paramsLowPrice:null,         //最低价
        paramsHighPrice:null,        //最高价
        paramsLowModelYear:null,     //最低年份
        paramsHighModelYear:null,    //最高年份
        paramsOutputStandard:null,   //排放标准
        paramsDriveMethod:null,      //驱动类型
        paramsLowShowMileage:null,   //里程最低
        paramsHighShowMileage:null,  //里程最高
        paramsOutputVolume:null,     //排量
        paramsOilType:null,          //燃料类型
        paramsBodyColor:null,        //颜色
        pageSize:16,                 //每页数量
        currPage:1,                  //页码
      },
      filterData:{
        'hotBrands':[
          '丰田', '大众', '宝马', '奥迪', '奔驰', '别克', '福特', '本田', '马自达', '日产', '吉利', '奇瑞', '雪佛兰'
        ],
        'prices':[
          {name:'不限', low:null, height:null},
          {name:'3万以下', low:0, height:3},
          {name:'3-5万', low:3, height:5},
          {name:'5-8万', low:5, height:8},
          {name:'8-10万', low:8, height:10},
          {name:'10-15万', low:10, height:15},
          {name:'15-20万', low:15, height:20},
          {name:'20-30万', low:20, height:30},
          {name:'30万以上', low:30, height:100},
        ],
        'mileages':[
          {name:'1万公里内', value:'1', low:0, height:1},
          {name:'3万公里内', value:'2', low:0, height:3},
          {name:'5万公里内', value:'3', low:0, height:5},
          {name:'8万公里内', value:'4', low:0, height:8},
          {name:'10万公里内', value:'5', low:0, height:10},
          {name:'10万公里以上', value:'6', low:10, height:100},
        ],
        'pl':[
          {name:'1.0L以下', value:'1'},
          {name:'1.0-1.6L', value:'2'},
          {name:'1.6-2.0L', value:'3'},
          {name:'2.0-3.0L', value:'4'},
          {name:'3.0-4.0L', value:'5'},
          {name:'4.0L以上', value:'6'},
        ],
      },
      cities:[],
      tags: [],
      isShowMoreBrand:false,
      isShowMoreSerial:false,
    };
  },
  mounted() {
    this.activeName = this.$route.name
    this.title = this.$route.meta.title
    this.getOptions()

  },
  methods: {
    getOptions(){
      getOptions().then(response => {
        this.options=response.data
      })
      getBrandOption().then(response => {
        this.brands=response.data
        this.letterBrand(this.brands)
      })
      getCityOption().then(response => {
        this.cities=response.data
      })
      getIp().then(response => {
        this.filter.paramsPdCityName=response.data
        this.getCars()
      })
      this.getSerialOption();
    },
    getSerialOption(){
      getSerialOption({brand:this.filter.paramsBrandReal}).then(response => {
        this.serials=response.data
      })
    },
    getCars(){
      this.loading = true
      getCars(this.filter).then(response => {
        this.cars=response.data.list
        this.loading = false
      })

    },
    onClickCity(city){
      this.filter.paramsPdCityName = city
      this.getCars()
    },
    onClickBrand(brand){
      this.filter.paramsBrandReal = brand
      this.filter.paramsPopSerial = null
      this.isShowMoreBrand = false
      if(!brand){
        this.onClickDelTagName('paramsBrandReal')
      }
      else{
        this.updateTag('品牌', 'paramsBrandReal', this.filter.paramsBrandReal)
      }
      this.getSerialOption();
      this.getCars()
    },
    onClickSerial(serial){
      this.isShowMoreSerial = false
      this.filter.paramsPopSerial = serial

      if(!serial){
        this.onClickDelTagName('paramsPopSerial')
      }
      else{
        this.updateTag('车系', 'paramsPopSerial', this.filter.paramsPopSerial)
      }
      this.getCars()
    },
    onClickPrice(low, height){
      this.filter.paramsLowPrice = low
      this.filter.paramsHighPrice = height
      if(this.filter.paramsLowPrice>this.filter.paramsHighPrice){
        let m = this.filter.paramsLowPrice;
        this.filter.paramsLowPrice = this.filter.paramsHighPrice
        this.filter.paramsHighPrice = m
      }

      if(!height){
        this.onClickDelTagName('paramsHighPrice')
      }
      else{
        let name = this.filter.paramsLowPrice+'-'+this.filter.paramsHighPrice+"万"
        if(!this.filter.paramsLowPrice){
          name = this.filter.paramsHighPrice+'万以下'
        }
        else if(this.filter.paramsHighPrice>=100){
          name = this.filter.paramsLowPrice+'万以上'
        }

        let tagIndex = this.tags.findIndex(item=>item.filter=='paramsHighPrice')
        if (tagIndex < 0) {
          this.tags.push({ name: '车价：'+name, filter:'paramsHighPrice' });
        } else {
          this.tags[tagIndex].name = '车价：'+name
        }
      }


      this.getCars()
    },
    onChangeYear(){
      if(this.filter.paramsHighModelYear && this.filter.paramsLowModelYear>this.filter.paramsHighModelYear){
        let m = this.filter.paramsLowModelYear;
        this.filter.paramsLowModelYear = this.filter.paramsHighModelYear
        this.filter.paramsHighModelYear = m
      }
      let name = this.filter.paramsLowModelYear+'-'+this.filter.paramsHighModelYear+"年"
      if(!this.filter.paramsLowModelYear){
        name = this.filter.paramsHighModelYear+'年以下'
      }
      else if(!this.filter.paramsHighModelYear){
        name = this.filter.paramsLowModelYear+'年以上'
      }

      let tagIndex = this.tags.findIndex(item=>item.filter=='paramsHighModelYear')
      if (tagIndex < 0) {
        this.tags.push({ name: '年款：'+name, filter:'paramsHighModelYear' });
      } else {
        this.tags[tagIndex].name = '年款：'+name
      }
      this.getCars()
    },
    onChangeOutputStandard(){
      this.updateTag('排放标准', 'paramsOutputStandard', this.filter.paramsOutputStandard)
      this.getCars()
    },
    onChangeDriveMethod(){
      this.updateTag('驱动类型', 'paramsDriveMethod', this.filter.paramsDriveMethod)
      this.getCars()
    },
    onChangeMileage(){
      let mileageData = this.filterData.mileages[this.mileage]
      this.filter.paramsLowShowMileage = mileageData.low
      this.filter.paramsHighShowMileage = mileageData.height
      this.updateTag('里程', 'paramsHighShowMileage', mileageData.name)
      this.getCars()
    },
    onChangeOutputVolume(){
      this.updateTag('排量', 'paramsOutputVolume', this.filter.paramsOutputVolume)
      this.getCars()
    },
    onChangeOilType(){
      this.updateTag('燃料类型', 'paramsOilType', this.filter.paramsOilType)
      this.getCars()
    },
    onClickShow(id){
      this.$router.push({ path: '/car/detail/'+id})
    },
    onClickDelTagName(name){
      let tagIndex = this.tags.findIndex(item=>item.filter==name)
      this.tags.splice(tagIndex, 1);
      if(name=='paramsHighPrice'){
        this.filter.paramsLowPrice=null
      }
      if(name=='paramsHighModelYear'){
        this.filter.paramsLowModelYear=null
      }
      if(name=='paramsHighShowMileage'){
        this.filter.paramsLowShowMileage=null
        this.mileage = null
      }
      this.filter[name]=null
      this.getCars()
    },
    updateTag($cn, param, val){
      let tagIndex = this.tags.findIndex(item=>item.filter==param)
      if (tagIndex < 0) {
        this.tags.push({ name: $cn+'：'+val, filter:param });
      } else {
        this.tags[tagIndex].name = $cn+'：'+val
      }
    },
    letterBrand(brands){

      const newBrands = []
      brands.sort((a,b) => a.brandInitial.charCodeAt(0) - b.brandInitial.charCodeAt(0))
      brands.map(item => {
        const key = item.brandInitial
        const index = newBrands.findIndex(subItem => subItem.key === key)
        if (index < 0) {
          newBrands.push({
            key: key,
            list: [item]
          })
        } else {
          newBrands[index].list.push(item)
        }

      })
      this.brands = newBrands
    },

  },
  watch:{
    $route(to){
      this.activeName = to.name
      this.title = to.meta.title
    }
  }
};
</script>

<style lang="scss" scoped>
.page-banner{
  height: 520px;
  background-image: url("../../assets/images/banner-4.jpg");
}


</style>
